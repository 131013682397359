import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { News } from "../../models/News";
import { Youtube } from "src/models/Youtube";
import { AudioNews } from "../../models/AudioNews"; 
import { Observable } from "rxjs";

@Injectable()
export class NewsProvider {
  constructor(public http: HttpClient) {}

  public basePath = "https://api.radioagudo.com.br/";
  public apiBase = this.basePath + "noticias/";
  public apiOld = "https://radioagudo.com.br/noticias/api/";

  getLastNews() {
    return new Promise((resolve, reject) => {
      this.http.get(this.apiBase).subscribe(
        (news: News[]) => {
          resolve(news);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getNewsBeforeId(id) {
    return new Promise((resolve, reject) => {
      this.http.get(this.apiBase + "before/" + id).subscribe(
        (news: News[]) => {
          resolve(news);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getNewsBeforeIdByCategory(id, category) {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.apiBase + "before-category/" + id + "/" + category)
        .subscribe(
          (news: News[]) => {
            resolve(news);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getNewsByCategory(category) {
    return new Promise((resolve, reject) => {
      this.http.get(this.apiBase + "category/" + category).subscribe(
        (news: News[]) => {
          resolve(news);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getYoutubeVideoList() {
    return new Promise((resolve, reject) => {
      this.http.get(this.basePath + "youtube/").subscribe(
        (news: Youtube[]) => {
          resolve(news);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getYoutubeVideoId(id: string) {
    return new Promise((resolve, reject) => {
      this.http.get(this.basePath + "youtube/id/?videoId=" + id).subscribe(
        (video: Youtube) => {
          resolve(video);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getHighlightsNews() {
    return this.http.get(this.apiBase + "destaque");
  }

  getNewsById(id) {
    return new Promise((resolve, reject) => {
      this.http.get(this.apiBase + "id/" + id).subscribe(
        (news) => {
          resolve(news);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getNewsAudiosById(id): Observable<AudioNews[]> {
    return this.http.get<AudioNews[]>(this.apiBase + "audios/" + id);
  }

  getNewsByKeywords(keywords, page) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.apiBase + "search", { keywords: keywords, page: page })
        .toPromise()
        .then((news: News[]) => {
          resolve(news);
        });
    });
  }
}
